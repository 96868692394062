<template>


    <div>
        <v-container>
            <br>
            <v-card flat>
                <v-tabs v-model="tab" @change="initialize()" background-color="primary" dark>
                    <v-tab v-for="item in items" :key="item.tab">
                        {{ item.tab }}
                    </v-tab>
                </v-tabs>

                <br>
                <br>

                <div v-if="tab==0">

                    <v-form ref="form" v-model="valid">
                        <v-container>

                            <v-row>

                                <v-col v-for="option in options" :key="option" class="py-0" cols="12" sm="12" md="12">
                                    <v-text-field v-model="option.value" required :label="option.option" outlined>
                                    </v-text-field>
                                </v-col>


                                <v-col class="py-0" cols="12" sm="4" md="4">

                                    <v-btn color="primary" :loading="loadSave" @click="save()">
                                        تعديل
                                    </v-btn>
                                </v-col>


                            </v-row>
                        </v-container>
                    </v-form>
                </div>

                <div v-if="tab==1">

                    <v-form ref="form" v-model="valid">
                        <v-container>

                            <v-row>

                                <v-col v-for="option in options" :key="option" class="py-0" cols="12" sm="12" md="12">
                                    <v-textarea v-model="option.value" required :label="option.option" outlined>
                                    </v-textarea>

                                    <v-col class="py-0" cols="6" sm="6" md="6">

                                        <img :src="imageUrl" height="350" v-if="imageUrl" />

                                        <v-col v-else class="py-0" cols="6" sm="6" md="6">


                                            <img :src="Url+'/uploude/options/'+option.img_file" height="350" />
                                        </v-col>


                                    </v-col>
                                </v-col>

                                <v-col class="py-0" cols="6" sm="12" md="6">
                                    <v-text-field label="اختر الصورة" @click='pickImg' v-model='imageName'
                                        prepend-icon='mdi-paperclip'>
                                    </v-text-field>
                                    <input type="file" style="display: none" ref="image" accept="image/*"
                                        @change="onFilePicked">
                                </v-col>


                                <v-col class="py-0" cols="12" sm="4" md="4">

                                    <v-btn color="primary" :loading="loadSave" @click="save();uploudeImg()">
                                        تعديل
                                    </v-btn>
                                </v-col>


                            </v-row>
                        </v-container>
                    </v-form>
                </div>


                <div v-if="tab==2">

                    <v-form ref="form" v-model="valid">
                        <v-container>

                            <v-row>





                                <v-col v-for="option in options" :key="option" class="py-0" cols="12" sm="12" md="12">
                                    <v-text-field v-model="option.value" required :label="option.option" outlined>
                                    </v-text-field>




                                </v-col>


                                <v-col class="py-0" cols="12" sm="4" md="4">

                                    <v-btn color="primary" :loading="loadSave" @click="save()">
                                        تعديل
                                    </v-btn>
                                </v-col>


                            </v-row>
                        </v-container>
                    </v-form>
                </div>


                <div v-if="tab==3">

                    <v-form ref="form" v-model="valid">
                        <v-container>

                            <v-row>

                                <v-col class="py-0" cols="6" sm="12" md="6">
                                    <v-text-field label="اختر الصورة" @click='pickImg' v-model='imageName'
                                        prepend-icon='mdi-paperclip'>
                                    </v-text-field>
                                    <input type="file" style="display: none" ref="image" accept="image/*"
                                        @change="onFilePicked">
                                </v-col>


                                <v-col v-for="option in options" :key="option" class="py-0" cols="6" sm="6" md="6">
                                    <v-col class="py-0" cols="6" sm="6" md="6">

                                        <img :src="imageUrl" height="350" v-if="imageUrl" />

                                        <v-col v-else class="py-0" cols="6" sm="6" md="6">


                                            <img :src="Url+'/uploude/options/'+option.img_file" height="350" />
                                        </v-col>


                                    </v-col>



                                </v-col>
                                <!-- 
                                <v-col  v-else class="py-0" cols="6" sm="6" md="6" >
                                    <v-col class="py-0" cols="6" sm="6" md="6">
                                    <v-img :src="imageUrl" height="350" width="500" v-if="imageUrl"  />
                                </v-col>
  </v-col> -->

                                <!-- <v-col class="py-0" cols="12" sm="4" md="4">
                                    <v-img :src="imageUrl" height="450" width="450" v-if="imageUrl" />
                                </v-col> -->

                                <v-col class="py-0" cols="12" sm="4" md="4">

                                    <v-btn color="primary" :loading="loadSave" @click="uploudeImg()">
                                        رفع
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-form>
                </div>


                <div v-if="tab==4">

                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row>

                                <v-col cols="12" sm="12" md="12">
                                    <v-btn color="green" style="color:#fff" @click="addOption()">اضافه </v-btn>
                                </v-col>
                            </v-row>


                            <v-row v-for=" (option, index) in options" :key="index">

                                <v-col class="py-0" cols="12" sm="8" md="8">

                                    <v-text-field v-model="option.value" required :label="option.option" outlined>
                                    </v-text-field>


                                    <v-textarea v-model="option.description" required :label="option.option" outlined>
                                    </v-textarea>



                                </v-col>


                                <v-col class="py-0" cols="12" sm="4" md="4">
                                    <v-btn color="red" style="color:#fff" @click="deleteOption(index,option.id)">حذف
                                    </v-btn>
                                </v-col>

                                <!-- <v-col class="py-0" cols="6" sm="12" md="6">
                                    <v-text-field label="اختر الصورة" @click='pickImg' v-model='imageName'
                                        prepend-icon='mdi-paperclip'>
                                    </v-text-field>
                                    <input type="file" style="display: none" ref="image" accept="image/*"
                                        @change="onFilePicked">
                                </v-col> -->

                                <v-col class="py-0" cols="6" sm="6" md="6">
                                    <v-img :src="Url+'/uploude/options/'+option.img_file" height="250" width="250" />
                                </v-col>






                                <v-col class="py-0" cols="12" sm="12" md="12">
                                    <br>
                                    <br>
                                    <hr style="border: 1px solid blue;">
                                    <br>
                                    <br>
                                </v-col>


                            </v-row>

                            <v-row>
                                <v-col class="py-0" cols="12" sm="4" md="4">

                                    <v-btn color="primary" :loading="loadSave" @click="save()">
                                        تعديل
                                    </v-btn>
                                </v-col>




                            </v-row>

                        </v-container>
                    </v-form>
                </div>

                <div v-if="tab==5">

                    <v-row>



                        <v-col class="py-0" cols="12" sm="6" md="6">
                            <v-select v-model="serId" :label="$t('cats')" :items="options" outlined item-text="value"
                                item-value="id"></v-select>
                        </v-col>

                        <v-col class="py-0" cols="12" sm="6" md="6"></v-col>

                        <v-col class="py-0" cols="6" sm="12" md="6">
                            <v-text-field label="اختر الصورة" @click='pickImg' v-model='imageName'
                                prepend-icon='mdi-paperclip'>
                            </v-text-field>
                            <input type="file" style="display: none" ref="image" accept="image/*"
                                @change="onFilePicked">
                        </v-col>





                        <v-col class="py-0" cols="12" sm="4" md="4">

                            <v-btn color="primary" :loading="loadSave" @click="uploudeImgSer()">
                                رفع
                            </v-btn>
                        </v-col>

                    </v-row>

                </div>


                <div v-if="tab==6">

<v-row>



    <v-col class="py-0" cols="12" sm="6" md="6">
        <v-select v-model="serId" :label="$t('cats')" :items="options" outlined item-text="value"
            item-value="id"></v-select>
    </v-col>

    <v-col class="py-0" cols="12" sm="6" md="6"></v-col>

    <v-col class="py-0" cols="6" sm="12" md="6">
        <v-text-field label="اختر الصورة" @click='pickImg' v-model='imageName'
            prepend-icon='mdi-paperclip'>
        </v-text-field>
        <input type="file" style="display: none" ref="image" accept="image/*"
            @change="onFilePicked">
    </v-col>





    <v-col class="py-0" cols="12" sm="4" md="4">

        <v-btn color="primary" :loading="loadSave" @click="uploudeImgSer()">
            رفع
        </v-btn>
    </v-col>

</v-row>

</div>
            </v-card>
        </v-container>
    </div>
</template>


<script>
    import Swal from "sweetalert2";
    import axios from "axios";
    export default {
        data() {
            return {


                imageFile: '',
                optionsSer: [],
                imagesUrl: '',
                imageName: '',
                serId: '',
                imageUrl: '',
                file_name: null,
                tab: 0,
                loading: false,
                options: [],
                loadSave: false,
                items: [{
                        tab: 'رواط التواصل الاجتامعي',

                    },



                    {
                        tab: 'من نحن'
                    },

                    {
                        tab: 'معلومات الاتصال'
                    },

                    {
                        tab: 'الصوره الرئيسية'
                    },

                    {
                        tab: 'خدمات المركز'
                    },

                    {
                        tab: 'رفع صور الخدمات'
                    },
                    {
                        tab: 'رفع صور غلاف الصفحات'
                    },
                ],
            }
        },

        created() {
            this.initialize();
            //this.initializePic();
        },
        methods: {

            deleteOption(index, id) {
                // this.sumPay();

                Swal.fire({
                    title: this.$t('sure_process'),
                    text: "",
                    heightAuto: false,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                }).then(result => {
                    if (result.value) {
                        this.options.splice(index, 1);
                        axios.delete("options/delete/" + id, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Accept: "application/json",
                                    Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                                }
                            })
                            .then(() => {
                                this.$swal.fire(this.$t('Successfully'), this.$t('done'), "success");
                                this.initialize();
                            })
                            .catch(() => {
                                this.$swal.fire(this.$t('not_successful'), this.$t('not_done'), "error");
                            });
                    }
                });




            },

            addOption() {
                this.options.push({
                    id: '',
                    option: 'services',
                    value: '',

                    active: 1,
                    description: '',
                    id: '',
                    img_file: '',
                    langid: 1,
                    option_categories_id: 5,
                    value: ''
                })

              

            },




            pickFile() {
                this.$refs.file.click()
            },
            onPickfile(e) {

                this.file = this.$refs.file.files[0];
                let file = e.target.files;
                this.file = file[0]
                this.file_name = file[0].name
            },

            pickImg() {
                this.$refs.image.click()
            },
            pickFiles() {
                this.$refs.images.click()

            },
            onFilePicked(e) {
                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName = files[0].name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = files[0]
                    })
                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },



            uploudeImgSer() {

                if (this.imageFile == '') {
                    this.loadSave = false;
                    this.$swal.fire({
                        title: "يجب اختيار صوره",
                        text: "",
                        icon: "error",
                        confirmButtonText: "اغلاق",
                    });

                }

                if (this.imageFile != null) {
                    this.loadSave = true;
                    var x = parseInt(this.tab) + 1;
                    let formData = new FormData();
                    const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                    formData.append('image', this.imageFile);


                    axios.post('options/uploadImage/' + this.serId,

                            formData, {
                                headers: {
                                    Authorizations: AuthStr,
                                    'Content-Type': 'multipart/form-data',
                                }
                            }
                        )
                        .then(() => {
                            this.loadSave = false;
  this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                            this.$refs.form.resetValidation();

                       

                            this.initialize();
                            this.img_old = ''
                            this.imageName = ''
                            this.imageFile = ''
                            this.imageUrl = ''

                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                        })

                }

            },

            initialize() {

                this.loading = true;
                try {

                    if (this.tab == 5) {
                        var x = 5;
                    } 
                    else  if (this.tab == 6) {
                        var x = 6;
                    }
                    
                    else {
                        var x = parseInt(this.tab) + 1;
                    }


                    axios.get('options/optionsByCat/' + x, {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                            }
                        }).then(res => {
                            this.loading = false;
                            this.options = res.data.data

                        })
                        .catch(() => {
                            this.loading = false;
                        });
                } catch (e) {

                }

            },
            uploudeImg() {


                if (this.imageFile == '') {
                    this.loadSave = false;
                    this.$swal.fire({
                        title: "يجب اختيار صوره",
                        text: "",
                        icon: "error",
                        confirmButtonText: "اغلاق",
                    });

                }

                if (this.imageFile != null) {

                    var x = parseInt(this.tab) + 1;
                    let formData = new FormData();
                    const AuthStr = 'Bearer ' + this.$store.state.AdminInfo.token;
                    formData.append('image', this.imageFile);


                    axios.post('options/uploadImage/' + this.options[0].id,

                            formData, {
                                headers: {
                                    Authorizations: AuthStr,
                                    'Content-Type': 'multipart/form-data',
                                }
                            }
                        )
                        .then(() => {

                            this.$refs.form.resetValidation();

                            this.loadSave = false;

                            this.initialize();
                            this.img_old = ''
                            this.imageName = ''
                            this.imageFile = ''
                            this.imageUrl = ''

                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                        })

                }
            },

            pickImg() {
                this.$refs.image.click()
            },
            onFilePicked(e) {
                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName = files[0].name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = files[0]
                    })
                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },



            save() {
                if (this.$refs.form.validate()) {


                    this.loadSave = true;

                    var data = {
                        data: this.options
                    };
                    var x = parseInt(this.tab) + 1;

                    this.axios
                        .put("options/update/" + x, data, {

                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                                Authorizations: "Bearer " + this.$store.state.AdminInfo.token,
                            },
                        })
                        .then(() => {

                            this.$refs.form.resetValidation();

                            this.loadSave = false;

                            this.initialize();


                            this.$swal.fire({
                                title: "تم تعديل ",
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });
                        })
                        .catch(() => {
                            this.loadSave = false;

                            this.$swal.fire({
                                title: "تاكد من ملى المعلومات",
                                text: "",
                                icon: "error",
                                confirmButtonText: "اغلاق",
                            });
                        });





                }
            },
        }

    }
</script>